// graphQL configureClient
import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost'
// import { ApolloClient } from 'apollo-client';
// import { split } from 'apollo-link';
// import { HttpLink } from 'apollo-link-http';
// import { WebSocketLink } from 'apollo-link-ws';
// import { getMainDefinition } from 'apollo-utilities';
// import { InMemoryCache } from 'apollo-cache-inmemory';

// import ApolloClient from "apollo-boost"
// const client = new ApolloClient({uri: 'http://localhost:8080/query'})
// const production = process.env.NODE_ENV === 'production'

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URL,
  credentials: 'include',
  headers: {
    Role: 'ADMIN',
  },
})

// const wsLink = new WebSocketLink({
//   uri: `ws${sURI}://${host}/query`,
//   // uri: 'ws://localhost:8080/query',
//   options: {
//     reconnect: true,
//   },
// });
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query);
//     return kind === 'OperationDefinition' && operation === 'subscription';
//   },
//   wsLink,
//   httpLink,
// );

const client = () =>
  new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link: httpLink,
    cache: new InMemoryCache(),
  })

export default client
