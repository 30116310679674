import React from 'react'
import PropTypes from "prop-types"
import { Link } from 'react-router-dom'
import {
  Menu, 
  Button,
} from 'antd'

class Navigation extends React.Component {
  constructor() {
      super()
      this.state = {
        options: [],
        pages: []
      }
      this.exibirCarros();
    }
  exibirCarros(){
    fetch("http://react/api/")
    .then((response)=>response.json())
    .then((responseJson)=>
    {
        this.setState({
            options: responseJson[0],
            pages: responseJson[2]
        });
    })
  }
  render() {
    return (
      <Menu
        mode="horizontal"
        defaultSelectedKeys={['2']}
        style={{ lineHeight: '64px' }}
      >
        {this.state.pages.map(row =>
          <Menu.Item key={row.url + row.id}><Link to={row.url}>{row.title}</Link></Menu.Item>
        )}
        {this.state.options.map(row =>
          <Menu.Item key={row.url + row.id}><a href="tel:+79179147658">{row.phone}</a></Menu.Item>
        )}
        <Menu.Item key="modalLog" className="text-center"><Button onClick={this.props.handleOpenModalOne} type="primary">Модалка 1</Button></Menu.Item>
        <Menu.Item key="modalReg" className="text-center"><Button onClick={this.props.handleOpenModalTwo} type="primary">Модалка 2</Button></Menu.Item>
      </Menu>
    )
  }
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation