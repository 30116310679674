import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import history from './utils/history'
import configureStore from './configureStore'
import Container from './components/Container/'
import Header from './components/Header/'
import Footer from './components/Footer/'
import MenuBar from './components/MenuBar/'
import { ApolloProvider } from 'react-apollo'
import configureClient from './configureClient'
import App from './container/App/';
import '../node_modules/antd/dist/antd.css';
import './styles/scss/application.scss'
import './styles/index.css';

const initialState = {}
const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById('root')

const client = configureClient()

ReactDOM.render(
	<ApolloProvider client={client}>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<MenuBar />
				<Container>
					<Header />
					<App />
					<Footer />
				</Container>
			</ConnectedRouter>
		</Provider>
	</ApolloProvider>, 
	MOUNT_NODE,
);
