import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const Markdown = ({ title, markdown }) => (
  <div>
    <h1>{title}</h1>
    <ReactMarkdown source={markdown} />
  </div>
)

Markdown.propTypes = {
  title: PropTypes.string,
  markdown: PropTypes.string,
}

export default Markdown
