import React from 'react'
import Markdown from '../../components/Markdown'
import TablesOptions from './tablesOptions';
import TablesNews from './tablesNews';
import './index.scss';

const title = 'Главная страница'
const markdown = `## Информация о проекте
Текстович главной страницы
`
class HomePage extends React.Component{
	constructor(){
        super();
        this.state={
            carros: [],
            users: []
        };
        this.exibirCarros();
    }

    exibirCarros(){
        fetch("http://react/api/")
        .then((response)=>response.json())
        .then((responseJson)=>
        {
            this.setState({
                carros: responseJson[0],
                users: responseJson[1]
            });
        })
    }
	render(){
		return (
		    <section className="BodyPage">
		    	<Markdown className="title-page" title={title} markdown={markdown} />
		    	<TablesOptions arraycarros={this.state.carros} />
          		<TablesNews arrayusers={this.state.users} />
		    </section>
		  )
	}
}

export default HomePage