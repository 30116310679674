import React from 'react'
import {
  Modal,
  Row, 
  Col, 
  Button, 
  Form, 
  Icon, 
  Input, 
  Checkbox,
} from 'antd'
class HeaderSite extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll();
  };
  render(){
    const { getFieldDecorator } = this.props.form;
    return(
      <Modal
        title="Авторизация"
        visible={this.props.isOpenModalLogin}
        onOk={this.props.onOkModalLogin}
        okText="Ввойти"
        onCancel={this.props.onCancelModalLogin}
        cancelText="Отмена"
        footer={null}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24}}>
            <Form onSubmit={this.handleSubmit}>
              <Form.Item>
                {getFieldDecorator('login', {
                  rules:[
                    {
                      required: true,
                      message: 'Логин не введен!',
                    }
                  ]
                })(<Input 
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Логин"
                />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules:[
                    {
                      required: true,
                      message: 'Пароль не введен!',
                    }
                  ]
                })(<Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Пароль"
                />)}
              </Form.Item>
              <Form.Item>
                <Checkbox>Запомнить меня</Checkbox>
                <a className="login-form-forgot" href="/">
                  Забыли пароль?
                </a>
                <Button block={true} type="primary" htmlType="submit">
                  Авторизоваться
                </Button>
                Или <a href="/">зарегистрируйтесь прямо сейчас!</a>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    )
  }
}
export default Form.create({ name: 'login' })(HeaderSite) 