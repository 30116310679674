import React from 'react'
import {
  Layout,
  Affix 
} from 'antd'
import TopMenu from './navigation'
import ModalLogin from './modalLogin'
import ModalRegister from './modalRegister'
const {Header} = Layout;
class HeaderSite extends React.Component {
  constructor() {
      super()
      this.state = {
        showModal: false,
        showModal2: false
      }
      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleOpenModal2 = this.handleOpenModal2.bind(this);
    }

  handleOpenModal () {
    this.setState({ showModal: true, });
  };
  handleOk = e => {
    this.setState({ showModal: false, });
  };
  handleCancel = e => {
    this.setState({ showModal: false, });
  };

  handleOpenModal2 () {
    this.setState({ showModal2: true, });
  };
  handleOk2 = e => {
    this.setState({ showModal2: false, });
  };
  handleCancel2 = e => {
    this.setState({ showModal2: false, });
  };

  render(){
    return(
      <Affix>
        <Header>
          <TopMenu
            handleOpenModalOne={this.handleOpenModal}
            handleOpenModalTwo={this.handleOpenModal2}
          />
          <ModalLogin 
            isOpenModalLogin={this.state.showModal} 
            onOkModalLogin={this.handleOk}
            onCancelModalLogin={this.handleCancel}
          />
          <ModalRegister 
            isOpenModalReg={this.state.showModal2}
            onOkModalReg={this.handleOk2}
            onCancelModalReg={this.handleCancel2}
          />         
        </Header>
      </Affix>
    )
  }
}
export default HeaderSite