import React from 'react'
import Markdown from '../../components/Markdown'

const title = 'О проекте'
const markdown = `## Информация о проекте
Текстович
`
class AboutPage extends React.Component{
	render(){
		return (
		    <section className="BodyPage">
		    	<Markdown className="title-page" title={title} markdown={markdown} />
		    </section>
		  )
	}
}
export default AboutPage