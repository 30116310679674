import React from 'react'
import { 
  Affix, 
  Icon, 
  Button,
  Dropdown,
  Menu,
} from 'antd'
const { SubMenu } = Menu;
const menu = (
  <Menu>
    <Menu.Item>1st menu item</Menu.Item>
    <Menu.Item>2nd menu item</Menu.Item>
    <SubMenu title="sub menu">
      <Menu.Item>3rd menu item</Menu.Item>
      <Menu.Item>4th menu item<Icon type="appstore" theme="twoTone" /></Menu.Item>
    </SubMenu>
    <SubMenu title="disabled sub menu" disabled>
      <Menu.Item>5d menu item</Menu.Item>
      <Menu.Item>6th menu item</Menu.Item>
    </SubMenu>
  </Menu>
);

class MenuBar extends React.Component {
  handleClick = () => {
    const wrapper = document.getElementById('wrapMenu')
    wrapper.classList.toggle('is-nav-open')
  }
  render(){
    return(
      <Affix>
        <div id="wrapMenu" className="wrapMenu">
          <div className="nav">
            <Button
              type="button"
              className="nav__icon"
              onClick={this.handleClick}
            >
              <Icon type="menu" className="color2"/>
            </Button>
            <Menu defaultSelectedKeys={['1']} mode="inline">
              <Menu.Item>Главная</Menu.Item>
              <Menu.Item>Услуги</Menu.Item>
              <SubMenu title="О нас">
                <Menu.Item>Контакты</Menu.Item>
                <Menu.Item>Отзывы</Menu.Item>
              </SubMenu>
              <SubMenu title="Акции" disabled>
                <Menu.Item>15% скидка</Menu.Item>
                <Menu.Item>20% скидка</Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </div>
    </Affix>
    )
  }
}

export default MenuBar
