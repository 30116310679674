import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'E-Mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Slogan',
    dataIndex: 'slogan',
    key: 'slogan',
  },
];
class TablesOptions extends React.Component{
    constructor() {
      super()
      this.state = {
        options: []
      }
      this.exibirCarros();
    }

    exibirCarros(){
      fetch("http://react/api/")
      .then((response)=>response.json())
      .then((responseJson)=>
      {
          this.setState({
              options: responseJson[0]
          });
      })
    }
    render(){
        return(
          <div>
           <Table className="tableOption" dataSource={this.state.options} columns={columns} />
          </div>
        );
    }
}

export default TablesOptions