import React from 'react';

import Home from '../Home'
import AboutPage from '../AboutPage'
import { Switch, Route } from 'react-router-dom'
import {Layout} from 'antd'
const {Content} = Layout

class App extends React.Component{
    render(){
        return(
          <Content>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={AboutPage} />
            </Switch>
          </Content>
        );
    }
}

export default App