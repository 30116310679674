import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Текст',
    dataIndex: 'text',
    key: 'text',
  },
  {
    title: 'Картинка',
    dataIndex: 'image',
    key: 'image',
    render: srcImg => <img src={srcImg} alt="" />,
  },
];

class TablesNews extends React.Component{
  constructor() {
      super()
      this.state = {
        news: []
      }
      this.exibirCarros();
    }

    exibirCarros(){
      fetch("http://react/api/")
      .then((response)=>response.json())
      .then((responseJson)=>
      {
          this.setState({
              news: responseJson[1]
          });
      })
    }
    render(){
        return(
          <div>
           <Table className="tableNews" dataSource={this.state.news} columns={columns} />
          </div>
        );
    }
}

export default TablesNews