import React from 'react'
import {
  Modal,
  Row, 
  Col,  
  Button, 
  Form, 
  Icon, 
  Input, 
  Checkbox,
  Tooltip,
  Cascader,
  Select,
  AutoComplete,
} from 'antd'

const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;
const residences = [
  {
    value: 'kazan',
    label: 'Казань',
    children: [
      {
        value: 'vaxitov',
        label: 'Вахитовский',
        children: [
          {
            value: 'center',
            label: 'Центр',
          },
          {
            value: 'east',
            label: 'Восток',
          },
        ],
      },
    ],
  },
  {
    value: 'moskva',
    label: 'Москва',
    children: [
      {
        value: 'zapolyarie',
        label: 'Заполярие',
        children: [
          {
            value: 'west',
            label: 'Запад',
          },
        ],
      },
    ],
  },
];
class ModalRegister extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Два пароля, которые вы вводите, несовместимы!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.ru', '.рф', '.tatar'].map(domain => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };
  render(){
    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '7',
    })(
      <Select style={{ width: 70 }}>
        <Option value="7">+7</Option>
        <Option value="1">+1</Option>
      </Select>,
    );

    const websiteOptions = autoCompleteResult.map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));
    return(
      <Modal
        title="Регистрация"
        visible={this.props.isOpenModalReg}
        onOk={this.props.onOkModalReg}
        okText="Регистрация"
        onCancel={this.props.onCancelModalReg}
        cancelText="Отмена"
        footer={null}
      >
        <Row gutter={[4, 4]}>
          <Col xs={{ span: 24}}>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Form.Item label="Почта">
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'Почта введена не верна!',
                    },
                    {
                      required: true,
                      message: 'Пожалуйста, укажите свою электронную почту!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Пароль" hasFeedback>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Пожалуйста, укажите пароль!',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input.Password />)}
              </Form.Item>
              <Form.Item label="Подтвердите пароль" hasFeedback>
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      required: true,
                      message: 'Пожалуйста, подтвердите Ваш пароль!',
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Никнейм&nbsp;
                    <Tooltip title="Ваш псевданим на сайте">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('nickname', {
                  rules: [{ required: true, message: 'Пожалуйста, введите Ваш никнейм!', whitespace: true }],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Место жительства">
                {getFieldDecorator('residence', {
                  initialValue: ['Казань', 'Москва', 'Санкт-Петербург'],
                  rules: [
                    { type: 'array', required: true, message: 'Пожалуйста, выберите место Вашего обычного проживания!' },
                  ],
                })(<Cascader options={residences} />)}
              </Form.Item>
              <Form.Item label="Номер телефона">
                {getFieldDecorator('phone', {
                  rules: [{ required: true, message: 'Пожалуйста, введите Ваш номер телефона!' }],
                })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} />)}
              </Form.Item>
              <Form.Item label="Веб-сайт">
                {getFieldDecorator('website', {
                  rules: [{ required: true, message: 'Пожалуйста, введите Ваш веб-сайт!' }],
                })(
                  <AutoComplete
                    dataSource={websiteOptions}
                    onChange={this.handleWebsiteChange}
                    placeholder="wznk.ru"
                  >
                    <Input />
                  </AutoComplete>,
                )}
              </Form.Item>
              <Form.Item label="Код сaptcha" extra="Мы должны убедиться, что Вы не робот.">
                <Row gutter={8}>
                  <Col span={12}>
                    {getFieldDecorator('captcha', {
                      rules: [{ required: true, message: 'Пожалуйста, введите код, который вы получили!' }],
                    })(<Input />)}
                  </Col>
                  <Col span={12}>
                    <Button>Получить код</Button>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                {getFieldDecorator('agreement', {
                  valuePropName: 'checked',
                })(
                  <Checkbox>
                    Я прочитал <a href="/">соглашение</a>
                  </Checkbox>,
                )}
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  Регистрация
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    )
  }
}

export default Form.create({ name: 'register' })(ModalRegister)